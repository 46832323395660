.adyen-checkout-paybybank_AIS_DD {
  margin-bottom: 16px;
}
.adyen-checkout-paybybank_AIS_DD__description-header {
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 500;
  color: #00112c;
}
.adyen-checkout-paybybank_AIS_DD__description-body {
  font-weight: 400;
  list-style-type: disc;
  color: #687282;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

@media (max-width: 330px) {
  .adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brands {
    display: none;
  }
}
@media (max-width: 360px) {
  .adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:nth-child(2) {
    display: none;
  }
}
@media (max-width: 390px) {
  .adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:nth-child(3) {
    display: none;
  }
}
@media (max-width: 420px) {
  .adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:nth-child(4) {
    display: none;
  }
}
.adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brand-number {
  text-overflow: clip;
  white-space: nowrap;
}